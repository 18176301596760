import React from 'react'
// import PropTypes from 'prop-types'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import OT2 from '../../components/OT-2'

interface ParamsInterface {
  page?: string;
}

type PageProps = {
  params: ParamsInterface,
}

const OT2Page = (props: PageProps) => {
  const {page} = props.params
  return (
    <Page>
      <SEO
        title="OT-2 Liquid Handler | Opentrons Lab Automation from $5,000"
        description="Lab automation has never been easier. The OT-2 can automate hundreds of life science protocols and workflows. If you can manually pipette it, you can automate it."
      />
      <OT2 page={page} />
    </Page>
  )
}

export default OT2Page
